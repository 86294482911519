.app {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0 1em;
  margin: 1em auto;
  margin-bottom: var(--gap);
}

.app__header {
  display: flex;
  flex-direction: column;
}

.app__logo-block {
  height: 48px;
}

.app__search-bar {
  margin-top: 1em;
}

.app__body {
  margin-top: 1.2em;
  display: flex;
  flex: 1;
}

.app__sidebar {
  display: none;
  padding: var(--gap);
  background-color: var(--color-dark-grey);
}

.app__content {
  width: 100%;
  padding: var(--gap);
  background-color: white;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.app__breadcrumbs {
  margin-bottom: 2em;
}

@media screen and (min-width: 767px) {
  .app {
    min-width: 760px;
    max-width: 1200px;
  }
  .app__header {
    flex-direction: row;
    align-items: center;
  }
  
  .app__search-bar {
    margin-top: 0;
    margin-left: auto;
    width: 32%;
  }

  .app__sidebar {
    display: block;
    width: 25%;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  
  .app__content {
    width: 75%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  
  .app__viewer {
    padding: 0 6.4vw;
  }
}
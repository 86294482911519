:root {
  --gap: 2em;
  --border-radius: 4px;
  --color-grey: #797979;
  --color-dark-grey: #3f4652;
  --color-blue: #5e90e3;
  --color-orange: #ff7a59;
  --color-white: white;
  --color-black: black;
  --font-size-large: 18px;
  --font-size: 16px;
  --font-size-small: 14px;
  --font-size-smallest: 12px;
  --font-size-heading: 24px;
}

* {
  box-sizing: border-box;
  outline: inherit;
}

body {
  background-attachment: fixed; 
  background-image: linear-gradient(to right, #02b6b6 0%, #126eb5 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  min-height: 100vh;
  font-size: 16px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Proxima Nova', 'Open Sans', 'Helvetica Neue', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}
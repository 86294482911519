.search-bar {
  display: flex;
  align-items: center;
  height: 42px;
  background: #ffffff4d;
  padding: 8px 16px;
  border-radius: var(--border-radius);
  color: var(--color-white);
  transition: background 0.15s;
}
.search-bar_is-focused_yes {
  background: var(--color-white);
  color: var(--color-black);
}
.search-bar_is-focused_no:hover {
  background: #ffffff66;
}

.search-bar__input {
  background: none;
  width: 100%;
  height: 100%;
  border: none;
  color: inherit;
  font-size: var(--font-size);
}

.search-bar__input::placeholder {
  color: inherit;
}
.search-bar__input:focus::placeholder {
  color: #cdd7e1; /* almost the same as magnifier color */
}

.search-bar__button {
  background: none;
  background-image: url(./magnifier.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  border: none;
  width: 28px;
  height: 28px;
  padding: 0;
  cursor: pointer;
}
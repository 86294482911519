.tree {
  padding: 0;
  font-size: var(--font-size-small);
}
.tree_is-root_yes {
  margin: -8px 0;
}
.tree_is-root_no {
  margin-left: 16px;
}

.tree__item {
  margin: 8px 0;
  list-style: none;
}

.tree__link,
.tree__link:visited {
  text-decoration: none;
  color: var(--color-white);
}
.tree__link:hover {
  text-decoration: underline;
}
.tree__link_is-bold_yes {
  font-weight: bolder;
}
.tree__link.tree__link_is-highlighted_yes {
  color: var(--color-orange);
}
.structure-viewer {}

.structure-viewer__item {
  display: flex;
  align-items: center;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 36px;
  min-height: 36px;
  padding: 0.5em 0 0.5em 56px;
  margin-bottom: 24px;
  font-size: var(--font-size);
}
.structure-viewer__item:last-of-type {
  margin-bottom: 0;
}
.structure-viewer__item_type_folder {
  background-image: url(./folder.png);
}
.structure-viewer__item_type_article {
  background-image: url(./article.png);
}

.structure-viewer__link,
.structure-viewer__link:visited {
  text-decoration: none;
}

.structure-viewer__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-dark-grey);
  border-bottom: 1px dashed transparent;
}
.structure-viewer__link:hover .structure-viewer__name {
  border-color: var(--color-dark-grey);
}
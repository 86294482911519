.logo-block {
  display: flex;
  align-items: center;
  color: var(--color-white);
  height: inherit;
}

.logo-block__image {
  height: 42px;
  width: auto;
  position: relative;
  top: 4px;
}

.logo-block__divider {
  margin: 0 1em;
  height: inherit;
  width: 1px;
  background: var(--color-white);
}

.logo-block__text {
  font-size: var(--font-size-large);
  line-height: 1em;
}
/* .article-viewer {} */

.article-viewer__heading {
  margin: 0;
  color: var(--color-blue);
  font-size: var(--font-size-heading);
  line-height: 1.2em;
}

.article-viewer__timestamp {
  display: inline-block;
  margin-top: 1em;
  color: var(--color-grey);
  font-size: var(--font-size-smallest);
}

.article-viewer__body {
  margin-top: 1.6em;
  font-size: var(--font-size);
}

.article-viewer__body img {
  max-width: 100%;
  height: auto !important;
  cursor: pointer;
}
.breadcrumbs {
  font-size: var(--font-size-small);
  color: var(--color-grey);
}

.breadcrumbs__item {
  color: inherit;
}
.breadcrumbs__link,
.breadcrumbs__link:visited {
  text-decoration: none;
  color: var(--color-blue);
}
.breadcrumbs__link:hover {
  text-decoration: underline;
}

.breadcrumbs__divider {
  margin: 0 5px;
  font-size: var(--font-size-smallest);
}